/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useModal } from '../../src/utils/globalModal';
import { SafeGuardModal } from '../modals/safeguard';

// Change the HEX code of background/text color here
// MUST BE FULL 6 DIGIT HEX FOR IT TO WORK
export const backgroundColor = '#ff1b17';
export const textColor = '#f1defa';
export const backgroundGradient =
	'linear-gradient(90deg,#ff4e10,#ff1b17 28%,#ff0f47 53%,#ff0571 67%,#f101e5);';

// Here edit the JSX (HTML) For alert
// To not show any alert, uncomment this line
// export const Alert = null;
// and comment everything below
export const Alert = () => {
	const [open, openModal, closeModal] = useModal();

	return (
		<>
			<strong>Hey! </strong> New to Jane?{' '}
			<a
				href="#"
				onClick={e => {
					e.preventDefault();
					openModal();
				}}
			>
				Start here
			</a>
			<SafeGuardModal open={open} closeModal={closeModal} />
		</>
	);
};
