import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Wrapper, Heading } from '../../Home/common';
import SiteContainer from '../../SiteContainer';
import { Article } from '../../StyledContents';
import { ReactComponent as HostJaneIcon } from '../../../svgs/nav/HostJane.svg';
import GenericIcon from '../../GenericIcon';

const MascotContainer = styled(Wrapper)`
	background-color: #000;
	color: #fff;

	${Heading} {
		color: #fff;
		font-weight: 800;
		font-style: normal !important;
		.hostjane-mascot__icon {
			height: 1.4em;
			width: 1.4em;
			position: relative;
			bottom: -0.3em;
			svg {
				height: 1.4em;
				width: 1.4em;
			}
			@media screen and (min-width: ${props =>
					props.theme.siteApp.breakpoints.tablet}px) {
				margin-right: 10px;
			}
		}
	}

	${Article} {
		color: #fff;
		max-width: 715px;
		margin: 0 auto;
		text-align: center;
		font-size: 27px;
		line-height: 1.6;
		a {
			color: #fff;
			border-bottom: 2px dashed #fff;
			text-decoration: none;
			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}
		@media screen and (max-width: 767px) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.hostjane-mascot__image {
		max-width: 715px;
		margin: 35px auto 0;
	}
`;
export default function Mascot({ children, title, imageData }) {
	return (
		<MascotContainer className="hostjane-mascot">
			<SiteContainer>
				<Heading>
					<GenericIcon className="hostjane-mascot__icon">
						<HostJaneIcon />
					</GenericIcon>
					{title}
				</Heading>
				<Article>
					<MDXRenderer>{children}</MDXRenderer>
				</Article>
				<div className="hostjane-mascot__image">
					<Img fluid={imageData} alt={title} />
				</div>
			</SiteContainer>
		</MascotContainer>
	);
}
