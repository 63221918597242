import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

function Footer({ imageData, credit, className, SVGComponent }) {
	return (
		<BackgroundImage
			Tag="section"
			className={`hostjane-about-footer ${className}`}
			fluid={imageData}
			backgroundColor="#E3E3E3"
		>
			<div className="hostjane-about-footer__scorpio">
				<SVGComponent />
			</div>
			<p className="hostjane-about-footer__credit">
				Image credit: {credit}
			</p>
		</BackgroundImage>
	);
}

export default styled(Footer)`
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 314px;
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.tablet}px) {
		height: 314px;
	}
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		height: 374px;
	}

	.hostjane-about-footer__scorpio {
		width: 250px;
		height: 250px;
		svg {
			width: 100%;
			height: auto;
		}
	}

	.hostjane-about-footer__credit {
		margin: 0;
		padding: 0;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.8);
		position: absolute;
		right: 10px;
		bottom: 10px;
		font-style: italic;
	}
`;
