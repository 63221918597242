import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

function Hero({ imageData, className }) {
	return (
		<BackgroundImage
			Tag="section"
			className={className}
			fluid={imageData}
			backgroundColor="#E3E3E3"
		/>
	);
}

export default styled(Hero)`
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(100vh - 206px);
	max-height: 700px;
	z-index: -1;
	margin-top: 10px;
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.tablet}px) {
		height: calc(100vh - 156px);
	}
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		height: calc(100vh - 207px);
	}
`;
