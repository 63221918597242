import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import DismissableBanner from '../../components/DismissableBanner';
import MainNavigation from '../../components/MainNavigation';
import MainFooter from '../../components/MainFooter';

import Hero from '../../components/About/Hero';
import Mascot from '../../components/About/Mascot';
import Footer from '../../components/About/Footer';
import Story from '../../components/About/Story';

import { ReactComponent as ScorpioSvg } from '../../../site-data/about-jane/scorpio.svg';

import {
	Alert,
	backgroundColor,
	textColor,
	backgroundGradient,
} from '../../../site-data/about-jane/alert';
import TabletNavigation from '../../components/Home/TabletNavigation';

function AboutPage({ data, ...props }) {
	return (
		<Layout {...props} hasFooter={false} fullWidth>
			<SEO
				title={data.main.frontmatter.seoTitle}
				description={data.main.frontmatter.seoDescription}
				addPrefix={false}
			/>
			<DismissableBanner
				backgroundColor={backgroundColor}
				textColor={textColor}
				backgroundGradient={backgroundGradient}
			>
				{Alert === null ? null : <Alert />}
			</DismissableBanner>
			<MainNavigation />
			<TabletNavigation />
			<Hero
				imageData={
					data.main.frontmatter.heroImage.childImageSharp.fluid
				}
			/>
			<Mascot
				title={data.mascot.frontmatter.heading}
				imageData={
					data.mascot.frontmatter.mascotImage.childImageSharp.fluid
				}
			>
				{data.mascot.body}
			</Mascot>
			<Story>{data.main.body}</Story>
			<Footer
				imageData={
					data.main.frontmatter.footerImage.childImageSharp.fluid
				}
				credit={data.main.frontmatter.footerImageCredit}
				SVGComponent={ScorpioSvg}
			/>
			<MainFooter />
		</Layout>
	);
}

export default AboutPage;

export const pageQuery = graphql`
	query AboutJane {
		main: mdx(
			fileAbsolutePath: { glob: "**/site-data/about-jane/content.mdx" }
		) {
			frontmatter {
				seoDescription
				seoTitle
				pageTitle
				heroImage {
					childImageSharp {
						fluid(
							maxWidth: 1920
							webpQuality: 100
							jpegQuality: 100
						) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				footerImage {
					childImageSharp {
						fluid(
							maxWidth: 1920
							webpQuality: 100
							jpegQuality: 100
						) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				footerImageCredit
			}
			body
		}
		mascot: mdx(
			fileAbsolutePath: { glob: "**/site-data/about-jane/mascot.mdx" }
		) {
			frontmatter {
				heading
				mascotImage {
					childImageSharp {
						fluid(
							maxWidth: 1920
							webpQuality: 100
							jpegQuality: 100
						) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
			body
		}
	}
`;
