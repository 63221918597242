import React from 'react';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Wrapper } from '../../Home/common';
import SiteContainer from '../../SiteContainer';
import { Article } from '../../StyledContents';

const StoryWrapper = styled(Wrapper)`
	${SiteContainer} {
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.tablet}px) {
			padding-left: 40px;
			padding-right: 40px;
		}

		@media screen and (max-width: 767px) {
		padding-left: 25px;
		padding-right: 25px;
	}
	}



	${Article} {
		font-size: 22px;
		line-height: 1.8;
		blockquote {
			padding: 0 0 0 20px;
			margin: 15px;
			font-size: 26px;
			line-height: 1.8;
			border-left: 15px dotted #fd5c98;
		}
		h4 {
			font-size: 35px;
			margin-top: 0;
			margin-bottom: 10px;
			font-weight: 800;
			line-height: 1.8;
		}
		a {
			color: #000000;
			text-decoration: none;
			border-bottom: 2px dashed #222;
			&:hover {
				color: #000000;
				text-decoration: none;
			}
		}
		.midlothian {
			max-width: 100%;
            height: auto;
         }
		 .image-credit {
			display: block;
			font-size: 14px;
			line-height: calc(20 / 14);
			text-transform: uppercase;
			font-weight: 400;
			color: #555555;
		 }
	}
`;

const Columns = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
`;

const Column = styled.div`
	flex: 0 0 100%;
	width: 100%;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	@media screen and (min-width: 1024px) {
		flex-basis: calc(50% - 20px);
		width: calc(50% - 20px);
		margin-bottom: 0;
	}
`;

const AlignmentImage = styled.img`
	padding: 0;
	padding-top: ${props => (props.paddingTop ? '20px' : 0)};
	margin: ${props =>
		props.align === 'left' ? '0 10px 10px 0' : '0 0 10px 10px'};
	width: ${props => props.width || '150px'};
	display: inline-block;
	float: ${props => props.align};
`;

export default function Story({ children }) {
	return (
		<StoryWrapper>
			<SiteContainer>
				<Article>
					<Columns>
						<MDXProvider components={{ Column, AlignmentImage }}>
							<MDXRenderer>{children}</MDXRenderer>
						</MDXProvider>
					</Columns>
				</Article>
			</SiteContainer>
		</StoryWrapper>
	);
}